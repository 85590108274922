import { useAuth0 } from '@auth0/auth0-react'
import BarLoading from 'components/common/util/BarLoading'
import { useRouter } from 'next/router'
import useUser from 'lib/data/use-user'
import { UserAccountStatus } from 'gen/service/common'
import { BaseErrorContent, BaseErrorPageLayout } from '../components/error/BaseErrorPage'

export default function Index() {
  const {
    loginWithRedirect,
    user: auth0User,
    isAuthenticated,
    isLoading: auth0IsLoading,
    error: auth0Error
  } = useAuth0()
  const router = useRouter()
  const { user, loading: loadingUser, error } = useUser(auth0User ? {} : null)

  if (auth0IsLoading) {
    return <BarLoading className="h-screen" />
  } else if (auth0Error) {
    return (
      <BaseErrorPageLayout code={401}>
        <BaseErrorContent
          title={'Login failed'}
          description={auth0Error.message}
          buttons={[
            {
              text: 'Try Login Again',
              type: 'primary',
              href: '/login'
            }
          ]}
        />
      </BaseErrorPageLayout>
    )
  }

  const location = typeof window == 'object' ? window.location : undefined
  const isSui = location?.hostname?.startsWith('sui') || location?.hostname?.startsWith('dash')

  if (!isAuthenticated) {
    if (isSui) {
      router.replace('/discover')
    } else {
      loginWithRedirect()
    }
    return
  }

  if (loadingUser) {
    return <BarLoading className="h-screen" />
  }

  // if (!auth0User?.email_verified) {
  //   router.replace('/verify-email')
  //   return
  // }

  if (error?.status == 401) {
    router.replace('/signup')
    return
  }

  if (user?.accountStatus !== UserAccountStatus.ACTIVE) {
    router.replace('/signup')
    return
  }

  if (isSui) {
    router.replace('/discover')
    return
  }

  router.replace('/projects')
  return
}
