/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufStruct from "../../../google/protobuf/struct.pb"
import * as GoogleProtobufTimestamp from "../../../google/protobuf/timestamp.pb"

type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (
    keyof T extends infer K ?
      (K extends string & keyof T ? { [k in K]: T[K] } & Absent<T, K>
        : never)
    : never);

export enum Tier {
  FREE = "FREE",
  DEV = "DEV",
  PRO = "PRO",
  ENTERPRISE = "ENTERPRISE",
  ANONYMOUS = "ANONYMOUS",
}

export enum OrganizationRole {
  ORG_MEMBER = "ORG_MEMBER",
  ORG_ADMIN = "ORG_ADMIN",
}

export enum JoinOperator {
  AND = "AND",
  OR = "OR",
  THEN = "THEN",
}

export enum Permission {
  READ = "READ",
  WRITE = "WRITE",
  ADMIN = "ADMIN",
}

export enum NotificationType {
  GENERAL = "GENERAL",
  PROCESSOR_UPLOAD_FAILED = "PROCESSOR_UPLOAD_FAILED",
  PROCESSOR_UPLOAD_SUCCESS = "PROCESSOR_UPLOAD_SUCCESS",
  PROCESSOR_OBSOLETED = "PROCESSOR_OBSOLETED",
  PROCESSOR_STOPPED = "PROCESSOR_STOPPED",
  PROCESSOR_ACTIVATED = "PROCESSOR_ACTIVATED",
  PROCESSOR_GENERAL = "PROCESSOR_GENERAL",
  PROCESSOR_ERROR = "PROCESSOR_ERROR",
  EXPORT_TASK_FAILED = "EXPORT_TASK_FAILED",
  EXPORT_TASK_SUCCESS = "EXPORT_TASK_SUCCESS",
  BILLING_INVOICE = "BILLING_INVOICE",
  BILLING_PAYMENT = "BILLING_PAYMENT",
  BILLING_SUBSCRIPTION = "BILLING_SUBSCRIPTION",
}

export enum UserAccountStatus {
  PENDING = "PENDING",
  SET_USERNAME = "SET_USERNAME",
  BANNED = "BANNED",
  ACTIVE = "ACTIVE",
}

export enum ProjectVisibility {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export enum ProjectType {
  SENTIO = "SENTIO",
  SUBGRAPH = "SUBGRAPH",
}

export enum AggregateAggregateOps {
  AVG = "AVG",
  SUM = "SUM",
  MIN = "MIN",
  MAX = "MAX",
  COUNT = "COUNT",
}

export enum SelectorOperatorType {
  EQ = "EQ",
  NEQ = "NEQ",
  EXISTS = "EXISTS",
  NOT_EXISTS = "NOT_EXISTS",
  GT = "GT",
  GTE = "GTE",
  LT = "LT",
  LTE = "LTE",
  BETWEEN = "BETWEEN",
  NOT_BETWEEN = "NOT_BETWEEN",
  CONTAINS = "CONTAINS",
  NOT_CONTAINS = "NOT_CONTAINS",
  IN_COHORTS = "IN_COHORTS",
  NOT_IN_COHORTS = "NOT_IN_COHORTS",
}

export enum SegmentationQueryResourceType {
  EVENTS = "EVENTS",
  COHORTS = "COHORTS",
}

export enum SegmentationQueryAggregationAggregatePropertiesAggregationType {
  SUM = "SUM",
  CUMULATIVE_SUM = "CUMULATIVE_SUM",
  AVG = "AVG",
  MEDIAN = "MEDIAN",
  MIN = "MIN",
  MAX = "MAX",
  DISTINCT_COUNT = "DISTINCT_COUNT",
  CUMULATIVE_DISTINCT_COUNT = "CUMULATIVE_DISTINCT_COUNT",
  CUMULATIVE_COUNT = "CUMULATIVE_COUNT",
  LAST = "LAST",
  CUMULATIVE_LAST = "CUMULATIVE_LAST",
  FIRST = "FIRST",
  CUMULATIVE_FIRST = "CUMULATIVE_FIRST",
  PERCENTILE_25TH = "PERCENTILE_25TH",
  PERCENTILE_75TH = "PERCENTILE_75TH",
  PERCENTILE_90TH = "PERCENTILE_90TH",
  PERCENTILE_95TH = "PERCENTILE_95TH",
  PERCENTILE_99TH = "PERCENTILE_99TH",
}

export enum CohortsFilterAggregationOperatorType {
  EQ = "EQ",
  NEQ = "NEQ",
  GT = "GT",
  GTE = "GTE",
  LT = "LT",
  LTE = "LTE",
  BETWEEN = "BETWEEN",
  NOT_BETWEEN = "NOT_BETWEEN",
}

export enum CohortsFilterAggregationAggregatePropertiesAggregationType {
  SUM = "SUM",
  AVG = "AVG",
  MEDIAN = "MEDIAN",
  MIN = "MIN",
  MAX = "MAX",
  DISTINCT_COUNT = "DISTINCT_COUNT",
  LAST = "LAST",
  FIRST = "FIRST",
}

export enum ContractSource {
  PROCESSOR = "PROCESSOR",
  UI = "UI",
}

export enum ChannelType {
  UNKNOWN = "UNKNOWN",
  EMAIL = "EMAIL",
  SLACK = "SLACK",
  TELEGRAM = "TELEGRAM",
  WEBHOOK = "WEBHOOK",
  DISCORD = "DISCORD",
  PAGERDUTY = "PAGERDUTY",
}

export enum TabularDataColumnType {
  STRING = "STRING",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  LIST = "LIST",
  TIME = "TIME",
}

export enum RetentionQueryCriteria {
  OnOrAfter = "OnOrAfter",
  On = "On",
}

export enum RetentionQueryFilterTimeFilterType {
  Disable = "Disable",
  FirstInTimeRange = "FirstInTimeRange",
  FirstInGlobal = "FirstInGlobal",
}

export enum RetentionQueryIntervalUnit {
  Day = "Day",
  Week = "Week",
  Month = "Month",
}

export enum SystemSQLQueryAggregationAggregatePropertiesAggregationType {
  SUM = "SUM",
  AVG = "AVG",
  MEDIAN = "MEDIAN",
  MIN = "MIN",
  MAX = "MAX",
  DISTINCT_COUNT = "DISTINCT_COUNT",
}

export enum RichValueNullValue {
  NULL_VALUE = "NULL_VALUE",
}

export type UsageTracker = {
  apiSku?: string
  webuiSku?: string
  projectIdField?: string
  projectSlugField?: string
  projectOwnerField?: string
  versionField?: string
}

export type AccessMeta = {
  projectIdField?: string
  projectSlugField?: string
  ownerIdField?: string
  ownerNameField?: string
  processorIdField?: string
  processorVersionField?: string
}

export type User = {
  id?: string
  email?: string
  emailVerified?: boolean
  lastName?: string
  firstName?: string
  locale?: string
  nickname?: string
  picture?: string
  sub?: string
  updatedAt?: string
  createdAt?: string
  username?: string
  accountStatus?: UserAccountStatus
  tier?: Tier
}

export type UserInfo = {
  id?: string
  lastName?: string
  firstName?: string
  nickname?: string
  picture?: string
  username?: string
}


type BaseOwner = {
  tier?: Tier
}

export type Owner = BaseOwner
  & OneOf<{ user: User; organization: Organization }>

export type ProjectProjectMember = {
  user?: UserInfo
  role?: string
}

export type Project = {
  id?: string
  displayName?: string
  description?: string
  createdAt?: string
  updatedAt?: string
  slug?: string
  ownerId?: string
  owner?: Owner
  visibility?: ProjectVisibility
  type?: ProjectType
  members?: ProjectProjectMember[]
  multiVersion?: boolean
  ownerName?: string
  notificationChannels?: Channel[]
  views?: ProjectView[]
  supersetEnable?: boolean
  superset?: ProjectSuperset
  enableDisk?: boolean
  enableMaterializedView?: boolean
}

export type ProjectInfo = {
  id?: string
  displayName?: string
  description?: string
  createdAt?: string
  updatedAt?: string
  slug?: string
  owner?: string
  visibility?: ProjectVisibility
  type?: ProjectType
  multiVersion?: boolean
  supersetEnable?: boolean
  superset?: ProjectSuperset
  enableDisk?: boolean
  enableMaterializedView?: boolean
}

export type EventLogColumn = {
  id?: string
  size?: number
  name?: string
  accessorKey?: string
  enableHiding?: boolean
  enableSorting?: boolean
  enableResizing?: boolean
}

export type ColumnStateSort = {
  id?: string
  desc?: boolean
}

export type ColumnState = {
  columnSizing?: {[key: string]: number}
  columnVisibility?: {[key: string]: boolean}
  columnOrder?: string[]
  sorting?: ColumnStateSort[]
}

export type EventLogConfig = {
  columns?: EventLogColumn[]
  state?: ColumnState
}

export type ProjectViewProjectViewConfig = {
  eventLog?: EventLogConfig
}

export type ProjectView = {
  id?: string
  projectId?: string
  name?: string
  config?: ProjectViewProjectViewConfig
}

export type OrganizationMember = {
  user?: UserInfo
  role?: OrganizationRole
}

export type Organization = {
  id?: string
  oid?: string
  name?: string
  createdAt?: string
  updatedAt?: string
  members?: OrganizationMember[]
  displayName?: string
  logoUrl?: string
  projects?: ProjectInfo[]
  tier?: Tier
}

export type ApiKey = {
  id?: string
  name?: string
  ownerId?: string
  scopes?: string[]
  createdAt?: string
  updatedAt?: string
  expiresAt?: string
  source?: string
}

export type TimeRangeLite = {
  start?: string
  end?: string
  step?: number
  timezone?: string
}


type BaseTimeRangeTimeLike = {
}

export type TimeRangeTimeLike = BaseTimeRangeTimeLike
  & OneOf<{ relativeTime: TimeRangeRelativeTime; absoluteTime: string }>

export type TimeRangeRelativeTime = {
  unit?: string
  value?: number
  align?: string
}

export type TimeRange = {
  start?: TimeRangeTimeLike
  end?: TimeRangeTimeLike
  step?: string
  interval?: Duration
  timezone?: string
}

export type Duration = {
  value?: number
  unit?: string
}

export type Formula = {
  expression?: string
  alias?: string
  id?: string
  disabled?: boolean
  functions?: Function[]
}


type BaseArgument = {
}

export type Argument = BaseArgument
  & OneOf<{ stringValue: string; intValue: number; doubleValue: number; boolValue: boolean; durationValue: Duration }>

export type Function = {
  name?: string
  arguments?: Argument[]
}

export type Query = {
  query?: string
  alias?: string
  id?: string
  labelSelector?: {[key: string]: string}
  aggregate?: Aggregate
  functions?: Function[]
  disabled?: boolean
}

export type Aggregate = {
  op?: AggregateAggregateOps
  grouping?: string[]
}

export type Selector = {
  key?: string
  operator?: SelectorOperatorType
  value?: Any[]
}

export type SelectorExprLogicExpr = {
  expressions?: SelectorExpr[]
  operator?: JoinOperator
}


type BaseSelectorExpr = {
}

export type SelectorExpr = BaseSelectorExpr
  & OneOf<{ selector: Selector; logicExpr: SelectorExprLogicExpr }>

export type CohortsGroup = {
  joinOperator?: JoinOperator
  filters?: CohortsFilter[]
}

export type CohortsQuery = {
  joinOperator?: JoinOperator
  groups?: CohortsGroup[]
  name?: string
  id?: string
}


type BaseSegmentationQueryResource = {
  name?: string
  type?: SegmentationQueryResourceType
}

export type SegmentationQueryResource = BaseSegmentationQueryResource
  & OneOf<{ cohortsId: string; cohortsQuery: CohortsQuery }>

export type SegmentationQueryAggregationTotal = {
}

export type SegmentationQueryAggregationUnique = {
}

export type SegmentationQueryAggregationCountUnique = {
  duration?: Duration
}

export type SegmentationQueryAggregationAggregateProperties = {
  type?: SegmentationQueryAggregationAggregatePropertiesAggregationType
  propertyName?: string
}


type BaseSegmentationQueryAggregation = {
}

export type SegmentationQueryAggregation = BaseSegmentationQueryAggregation
  & OneOf<{ total: SegmentationQueryAggregationTotal; unique: SegmentationQueryAggregationUnique; countUnique: SegmentationQueryAggregationCountUnique; aggregateProperties: SegmentationQueryAggregationAggregateProperties }>

export type SegmentationQuerySelectorExprLogicExpr = {
  expressions?: SegmentationQuerySelectorExpr[]
  operator?: JoinOperator
}


type BaseSegmentationQuerySelectorExpr = {
}

export type SegmentationQuerySelectorExpr = BaseSegmentationQuerySelectorExpr
  & OneOf<{ selector: Selector; logicExpr: SegmentationQuerySelectorExprLogicExpr }>

export type SegmentationQuery = {
  resource?: SegmentationQueryResource
  alias?: string
  id?: string
  aggregation?: SegmentationQueryAggregation
  selectorExpr?: SegmentationQuerySelectorExpr
  groupBy?: string[]
  limit?: number
  functions?: Function[]
  disabled?: boolean
}

export type CohortsFilterAggregationTotal = {
}

export type CohortsFilterAggregationAggregateProperties = {
  type?: CohortsFilterAggregationAggregatePropertiesAggregationType
  propertyName?: string
}


type BaseCohortsFilterAggregation = {
  operator?: CohortsFilterAggregationOperatorType
  value?: Any[]
}

export type CohortsFilterAggregation = BaseCohortsFilterAggregation
  & OneOf<{ total: CohortsFilterAggregationTotal; aggregateProperties: CohortsFilterAggregationAggregateProperties }>

export type CohortsFilter = {
  symbol?: boolean
  name?: string
  aggregation?: CohortsFilterAggregation
  selectorExpr?: SelectorExpr
  timeRange?: TimeRangeLite
}

export type Contract = {
  address?: string
  name?: string
  chainId?: string
  id?: string
  source?: ContractSource
}

export type ErrorRecord = {
  id?: string
  namespace?: number
  code?: number
  namespaceCode?: number
  message?: string
  createdAt?: GoogleProtobufTimestamp.Timestamp
}

export type StringList = {
  values?: string[]
}


type BaseAny = {
}

export type Any = BaseAny
  & OneOf<{ intValue: number; longValue: string; doubleValue: number; stringValue: string; boolValue: boolean; dateValue: GoogleProtobufTimestamp.Timestamp; listValue: StringList }>

export type Channel = {
  id?: string
  projectId?: string
  type?: ChannelType
  slackWebhookUrl?: string
  emailAddress?: string
  name?: string
  customWebhookUrl?: string
  customHeaders?: {[key: string]: string}
  telegramReference?: string
  telegramChatId?: string
  slackTeam?: string
  slackChannel?: string
  pagerdutyConfig?: GoogleProtobufStruct.Struct
}

export type EventLogEntry = {
  message?: string
  timestamp?: string
  logLevel?: string
  logType?: string
  contractName?: string
  contractAddress?: string
  blockNumber?: string
  chainId?: string
  attributes?: GoogleProtobufStruct.Struct
  id?: string
  transactionHash?: string
  highlightedMessage?: string
  distinctId?: string
  eventName?: string
  logIndex?: number
  transactionIndex?: number
}

export type MatrixSample = {
  metric?: MatrixMetric
  values?: MatrixValue[]
}

export type MatrixMetric = {
  name?: string
  labels?: {[key: string]: string}
  displayName?: string
}

export type MatrixValue = {
  timestamp?: string
  value?: number
}

export type Matrix = {
  samples?: MatrixSample[]
  totalSamples?: number
}

export type DashboardSharingRequest = {
  sharingId?: string
  panelId?: string
  samplesLimit?: number
  timeRange?: TimeRangeLite
  version?: number
  variables?: GoogleProtobufStruct.Struct
  samplesOffset?: number
}

export type UserUsage = {
  tier?: Tier
  projects?: number
  alerts?: number
}

export type CoinIDAddressIdentifier = {
  address?: string
  chain?: string
}


type BaseCoinID = {
}

export type CoinID = BaseCoinID
  & OneOf<{ symbol: string; address: CoinIDAddressIdentifier }>

export type PriceSegmentationQuery = {
  id?: string
  alias?: string
  coinId?: CoinID[]
  disabled?: boolean
}

export type TabularData = {
  columns?: string[]
  columnTypes?: {[key: string]: TabularDataColumnType}
  rows?: GoogleProtobufStruct.Struct[]
  generatedAt?: GoogleProtobufTimestamp.Timestamp
  cursor?: string
}

export type Account = {
  name?: string
  id?: string
  contact?: string
  paymentInfo?: GoogleProtobufStruct.Struct
  ownerId?: string
  owner?: Owner
  address?: string
  paymentMethod?: string
}

export type ImportedProject = {
  name?: string
  project?: Project
  imported?: Project
}

export type ProjectSuperset = {
  projectId?: string
  createdAt?: GoogleProtobufTimestamp.Timestamp
  syncAt?: GoogleProtobufTimestamp.Timestamp
}


type BaseSegmentParameter = {
}

export type SegmentParameter = BaseSegmentParameter
  & OneOf<{ cohortId: string; allUsers: boolean }>

export type RetentionQueryFilterTimeFilter = {
  type?: RetentionQueryFilterTimeFilterType
}

export type RetentionQueryFilter = {
  propertyFilter?: SelectorExpr
  timeFilter?: RetentionQueryFilterTimeFilter
}

export type RetentionQueryResource = {
  eventNames?: string[]
  filter?: RetentionQueryFilter
}

export type RetentionQueryInterval = {
  value?: number
  unit?: RetentionQueryIntervalUnit
}

export type RetentionQuery = {
  resources?: RetentionQueryResource[]
  criteria?: RetentionQueryCriteria
  interval?: RetentionQueryInterval
  selectorExpr?: SelectorExpr
  groupBy?: string[]
  segmentBy?: SegmentParameter[]
  windowSize?: number
}

export type RetentionMatrixSample = {
  time?: GoogleProtobufTimestamp.Timestamp
  segmentParameter?: SegmentParameter
  labels?: {[key: string]: string}
  totalCount?: number
  counts?: number[]
  rates?: number[]
}

export type RetentionMatrix = {
  samples?: RetentionMatrixSample[]
}

export type ComputeStats = {
  computedAt?: GoogleProtobufTimestamp.Timestamp
  computeCostMs?: string
  binaryVersionHash?: string
  computedBy?: string
  isCached?: boolean
  isRefreshing?: boolean
}

export type ClickhouseStatusMutation = {
  undoneCnt?: number
}

export type ClickhouseStatusProcess = {
  query?: string
  queryKind?: string
  elapsed?: number
}

export type ClickhouseStatusProcesses = {
  processes?: ClickhouseStatusProcess[]
}

export type ClickhouseStatus = {
  mutations?: {[key: number]: ClickhouseStatusMutation}
  processes?: {[key: number]: ClickhouseStatusProcesses}
}

export type ProjectVariablesVariable = {
  key?: string
  value?: string
  isSecret?: boolean
  updatedAt?: GoogleProtobufTimestamp.Timestamp
}

export type ProjectVariables = {
  projectId?: string
  variables?: ProjectVariablesVariable[]
}

export type CachePolicy = {
  cacheTtlSecs?: number
  cacheRefreshTtlSecs?: number
  forceRefresh?: boolean
  noCache?: boolean
}

export type SystemSQLQueryAggregationTotal = {
}

export type SystemSQLQueryAggregationCountUnique = {
  duration?: Duration
}

export type SystemSQLQueryAggregationAggregateProperties = {
  type?: SystemSQLQueryAggregationAggregatePropertiesAggregationType
  propertyName?: string
}


type BaseSystemSQLQueryAggregation = {
}

export type SystemSQLQueryAggregation = BaseSystemSQLQueryAggregation
  & OneOf<{ total: SystemSQLQueryAggregationTotal; countUnique: SystemSQLQueryAggregationCountUnique; aggregateProperties: SystemSQLQueryAggregationAggregateProperties }>

export type SystemSQLQuery = {
  id?: string
  alias?: string
  name?: string
  tableName?: string
  aggregation?: SystemSQLQueryAggregation
  selectorExpr?: SelectorExpr
  groupBy?: string[]
  disabled?: boolean
}

export type Notification = {
  id?: string
  projectId?: string
  source?: string
  level?: string
  message?: string
  createdAt?: GoogleProtobufTimestamp.Timestamp
  type?: NotificationType
  ownerId?: string
  owner?: Owner
  project?: Project
  attributes?: {[key: string]: string}
  read?: boolean
  repeat?: number
}


type BaseRichValue = {
}

export type RichValue = BaseRichValue
  & OneOf<{ nullValue: RichValueNullValue; intValue: number; floatValue: number; bytesValue: Uint8Array; boolValue: boolean; stringValue: string; timestampValue: GoogleProtobufTimestamp.Timestamp; bigintValue: BigInteger; bigdecimalValue: BigDecimal; listValue: RichValueList; structValue: RichStruct }>

export type RichStruct = {
  fields?: {[key: string]: RichValue}
}

export type RichValueList = {
  values?: RichValue[]
}

export type RichStructList = {
  entities?: RichStruct[]
}

export type BigDecimal = {
  value?: BigInteger
  exp?: number
}

export type BigInteger = {
  negative?: boolean
  data?: Uint8Array
}