import { getAccessToken } from 'lib/data/use-access-token'
import { getStorageValue } from './use-local-storage'
import { UseApiOptions } from './use-api'

export default function withJsonApi<REQ, RES, INIT>(f: (REQ, INIT) => Promise<RES>, token?: string) {
  // const initReq = {
  //   pathPrefix: '',
  //   headers: {
  //     'content-type': 'application/json',
  //     authorization: `Bearer ${token}`,
  //   },
  // }
  // return async (req: REQ) => {
  //   return f(req, initReq)
  // }
  return withJsonApiAutoToken(f)
}

export function withJsonApiAutoToken<REQ, RES, INIT>(
  f: (REQ, INIT) => Promise<RES>,
  useIdToken = false,
  options?: UseApiOptions
) {
  return async (req: REQ, init?: any) => {
    const token = await getAccessToken(useIdToken)
    const adminMode = getStorageValue('sentio_admin_mode')

    const initReq = {
      pathPrefix: '',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      },
      ...(init || {})
    }
    if (options?.fromShare?.shareId) {
      let share = `${options.fromShare.shareId}`
      if (options.fromShare.panelId) {
        share += `/${options.fromShare.panelId}`
      }
      initReq.headers['share-dashboard'] = share
    }
    if (token != 'anonymous' && adminMode) {
      initReq.headers['x-admin-mode'] = 'true'
    }
    return f(req, initReq)
  }
}
