import useApi, { UseApiOptions } from './use-api'
import { GetUserInfoRequest, SignUpRequest, WebService } from 'gen/service/web'
import { withJsonApiAutoToken } from './with-json-api'
import { User } from 'gen/service/common'
import { useAuth0, User as Auth0User } from '@auth0/auth0-react'
import mixpanel from 'mixpanel-browser'
// import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'
import { UserAccountStatus } from '../../gen/service/common'
import * as Sentry from '@sentry/nextjs'

interface Req {
  email?: string
  subject?: string
}

export default function useUser(req: Req | null = {}, option?: UseApiOptions) {
  const { isAuthenticated } = useAuth0()
  const request = req?.email ? { email: req.email } : { subject: req?.subject }
  const useIdToken = !!req?.email || !!req?.subject
  const {
    data: user,
    mutate,
    loading,
    token,
    error
  } = useApi(WebService.GetUser, isAuthenticated ? request : null, useIdToken, option)
  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user.email,
        username: user.username
      })
    }
  }, [user])

  const saveUser = async (user: User, invitation?: string) => {
    const createUser = withJsonApiAutoToken(WebService.CreateUser, true)
    const u = await createUser({
      user,
      invitation
    } as SignUpRequest)
    mutate(u)
    return u
  }

  const saveIdentity = async (auth0User: Auth0User) => {
    if (user && !user.sub) {
      // a new identity for this user
      // call CreateUser to add the new identity
      await saveUser({
        email: auth0User.email,
        picture: auth0User.picture,
        nickname: auth0User.nickname,
        sub: auth0User.sub,
        emailVerified: auth0User.email_verified,
        lastName: auth0User.family_name,
        firstName: auth0User.given_name,
        locale: auth0User.locale
      })
    }
  }

  useEffect(() => {
    if (user) {
      mixpanel.identify(user.username)
      mixpanel.people.set({
        $first_name: user.firstName,
        $last_name: user.lastName,
        $avatar: user.picture,
        $email: user.email
      })
      mixpanel.track('login', {
        username: user.username
      })
      // datadogRum.setUser({
      //   id: user.username,
      //   name: user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : undefined,
      //   email: user.email
      // })
    }
    if (user?.accountStatus === UserAccountStatus.SET_USERNAME) {
      const path = window.location.pathname
      if (path && path != '/signup' && path != '/') {
        // redirect to set username page
        window.location.href = '/signup'
      }
    }
  }, [user])

  return {
    user,
    saveUser,
    loading,
    saveIdentity,
    token,
    error
  }
}

export function getUserDisplayName(user: User) {
  if (!user) {
    return ''
  }
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`
  }
  if (user.nickname) {
    return user.nickname
  }
  if (user.username) {
    return user.username
  }
  return ''
}

export function useUserInfo(req: GetUserInfoRequest | null = {}, option?: UseApiOptions) {
  return useApi(WebService.GetUserInfo, req, false, option)
}

export function useUserSearch(query: { query: string } | null, option?: UseApiOptions) {
  return useApi(WebService.SearchUsersInfo, query, false, option)
}
