import BarLoader from 'react-spinners/BarLoader'
import { LoaderHeightWidthProps } from 'react-spinners/helpers/props'
import { memo } from 'react'
import classNames from 'lib/classnames'

interface Props {
  hint?: React.ReactNode
  loading?: boolean
  className?: string
  iconClassName?: string
  width?: LoaderHeightWidthProps['width']
}

function _BarLoading({ hint = 'Loading Sentio', loading = true, className, iconClassName, width = 150 }: Props) {
  if (loading) {
    return (
      <div className={classNames('loading-container flex h-full flex-col justify-center overflow-hidden', className)}>
        <div className="loading-icon hidden justify-center">
          <img src="/logo-mini.png" alt="loading" className={classNames('max-w-16 max-h-16', iconClassName)} />
        </div>

        {hint && <div className="loading-text text-icontent text-gray my-2 hidden text-center font-medium">{hint}</div>}
        <div className="flex justify-center pt-1">
          <BarLoader
            color="#0756D5"
            loading={true}
            height={5}
            width={width}
            cssOverride={{
              borderRadius: '4px'
            }}
          />
        </div>
      </div>
    )
  }
  return null
}

export const BarLoading = memo(_BarLoading)

export default BarLoading
